import React, { useState } from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Button from '../../atoms/Button/Button'
import Checkbox from '../../atoms/Checkbox/Checkbox'

const MC_URL =
  'https://carefullyapp.us14.list-manage.com/subscribe/post?u=598e885bf8cc8e77caa905501&amp;id=64d55499c1'

function validateEmail(email) {
  const validationRegex = /\S+@\S+\.\S+/
  return validationRegex.test(email)
}

const StayInTouchForEmployersSection = ({
  title,
  description,
  tag,
  emailUpdatesConsent,
}) => {
  const [isEmailValid, setEmailValid] = useState(true)
  const [isEmailTouched, setEmailTouched] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [numberOfEmployees, setNumberOfEmployees] = useState('1-10')
  const [message, setMessage] = useState('')
  const [allowMarketing, setAllowMarketing] = useState(false)

  return (
    <section
      className="section is-medium has-background-dark has-text-white"
      id="contact-us"
    >
      <div className="container">
        <div className="columns">
          <div className="column is-10-tablet is-offset-1-tablet is-12-mobile">
            <div className="columns is-multiline">
              <div className="columns is-gapless is-multiline">
                <form>
                  <div className="column is-12">
                    <h2 className="mb-4">{title}</h2>
                    {description && (
                      <p className="body-large mb-1">{description}</p>
                    )}
                  </div>
                  {!isSubmitted ? (
                    <>
                      <div className="column is-8 py-0">
                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Email address
                          </label>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="Email address"
                              className="input body-small border-radius-23px"
                              onBlur={() => {
                                setEmailValid(validateEmail(email))
                              }}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                if (!isEmailTouched) setEmailTouched(true)
                              }}
                            />
                          </div>
                          <p className="help is-danger body-small">
                            {isEmailValid ? ' ' : 'This email is invalid'}
                          </p>
                        </div>

                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Full name
                          </label>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="Full name"
                              className="input body-small border-radius-23px"
                              onChange={(e) => {
                                setName(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Company
                          </label>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="Your company or business name"
                              className="input body-small border-radius-23px"
                              onChange={(e) => {
                                setCompany(e.target.value)
                              }}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Number of employees
                          </label>
                          <div className="select body-small">
                            <select
                              className="border-radius-23px"
                              value={numberOfEmployees}
                              onChange={(e) =>
                                setNumberOfEmployees(e.target.value)
                              }
                            >
                              <option value="1-10">1-10 employees</option>
                              <option value="11-50">11-50 employees</option>
                              <option value="51-100">51-100 employees</option>
                              <option value="101-500">101-500 employees</option>
                              <option value="more than 500">
                                500+ employees
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="column is-10 mb-2 mt-5 pt-0">
                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Message (optional)
                          </label>
                          <div className="control">
                            <textarea
                              className="textarea body-small border-radius-10px"
                              placeholder="Enter optional message"
                              onChange={(e) => {
                                if (e.target) {
                                  setMessage(e.target.value)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="column is-12 my-4">
                        <div className="field">
                          <Checkbox
                            handleChange={(isChecked) => {
                              setAllowMarketing(isChecked)
                            }}
                          >
                            {emailUpdatesConsent ||
                              'Click here to sign up for periodic email updates from Carefully'}
                          </Checkbox>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="has-text-centered mt-6">
                      <div className="contact-us-submitted like-h3">
                        Thanks for reaching out! You'll hear from us soon.
                      </div>
                    </div>
                  )}
                  {!isSubmitted && (
                    <div className="column">
                      <Button
                        disabled={!isEmailValid}
                        handleClick={async (e) => {
                          e.preventDefault()
                          setEmailValid(validateEmail(email))
                          if (!isEmailValid || !isEmailTouched) {
                            return
                          }
                          const payload = {
                            'gdpr[307389]': true,
                            MMERGE1: message,
                            MMERGE2: company,
                            MMERGE3: numberOfEmployees,
                            MMERGE4: allowMarketing ? 'Yes' : 'No',
                            MMERGE5: name,
                            tags: '11231293',
                            allowMarketing,
                            ...(tag ? { MMERGE6: tag } : {}),
                          }
                          console.table(payload)
                          const response = await addToMailchimp(
                            email,
                            payload,
                            MC_URL
                          )
                          console.log(response)
                          const hasError =
                            !response ||
                            !response.result ||
                            response.result === 'error'
                          const isAlreadySubscribed =
                            response.result &&
                            response.result.includes(
                              'already subscribed to list'
                            )
                          setSubmitted(true)
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

StayInTouchForEmployersSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  emailUpdatesConsent: PropTypes.string,
  tag: PropTypes.string,
}

export default StayInTouchForEmployersSection
