import React from 'react'
import Markdown from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../Layout'

import QuoteWithLogo from '../organisms/QuoteWithLogo/QuoteWIthLogo'
import Testimonials from '../organisms/Testimonials/Testimonials'
import ShortHorizontalLine from '../atoms/ShortHorizontalLine/ShortHorizontalLine'
import { replaceWithLineBreaks } from '../../utils'
import CheckMarkLine from '../molecules/CheckMarkLine/CheckMarkLine'
import PointsWithBigTextSection from '../organisms/PointsWithBigTextSection/PointsWithBigTextSection'
import StayInTouchForEmployersSection from '../organisms/StayInTouchForEmployersSection/StayInTouchForEmployersSection'

export const ForEmployersPageTemplate = ({
  mainSection,
  secondSection,
  thirdSection,
  heroWithImage,
  quoteWithImage,
  testimonials,
}) => {
  return (
    <div>
      <section className="fancy-background">
        <div className="container">
          <div className="columns is-flex-mobile is-multiline is-gapless has-text-white">
            <div className="column is-full-touch is-8-desktop half-section">
              <div className="half-container is-flex is-vertical-center is-horizontal-center">
                <div className="columns is-gapless is-multiline">
                  <div className="column is-full is-flex is-horizontal-center-touch">
                    <figure className="image is-140px-wide mb-6 mt-6">
                      <img
                        src={
                          !!mainSection.logo.src.childImageSharp
                            ? mainSection.logo.src.childImageSharp.fluid.src
                            : mainSection.logo.src
                        }
                        alt={mainSection.logo.alt}
                      />
                    </figure>
                  </div>
                  <div className="column is-full has-text-dark">
                    <div className="columns mb-1">
                      <div className="column hero-text pt-0-touch is-uppercase">
                        {mainSection.subtitle}
                      </div>
                    </div>
                    <div className="columns is-gapless mt-1 mb-0">
                      <div className="column">
                        <h1>{mainSection.title}</h1>
                      </div>
                    </div>
                    <ShortHorizontalLine className="my-4" isCenteredTouchOnly />
                    <div className="columns my-1">
                      <div className="column">
                        <p className="hero-text">
                          {mainSection.downloadDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-full-touch is-4-desktop half-container is-vertical-center is-flex">
              <div className="half-container is-flex is-vertical-center is-horizontal-center-touch">
                <figure className="image half-section-image">
                  <img
                    className="is-rounded"
                    src={
                      !!mainSection.bigImage.src.childImageSharp
                        ? mainSection.bigImage.src.childImageSharp.fluid.src
                        : mainSection.bigImage.src
                    }
                    alt={mainSection.bigImage.alt}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-dark has-text-white">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-8 is-12-touch has-text-centered-touch has-text-left-desktop mt-50-touch mt-23-mobile">
              <h2>{secondSection.title}</h2>
              <ShortHorizontalLine className="my-4" isCenteredTouchOnly />
              {secondSection.description && (
                <p className="body-large">
                  <Markdown options={{ forceInline: true }}>
                    {secondSection.description}
                  </Markdown>
                </p>
              )}
            </div>
            <div className="column is-4 is-12-touch is-flex is-flex-direction-row	is-flex-wrap-wrap is-align-content-space-between">
              {secondSection.benefits.map((b) => (
                <CheckMarkLine text={b} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <PointsWithBigTextSection
        hasGreyBackground
        title={thirdSection.title}
        description={thirdSection.description}
        items={thirdSection.items}
      />
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-4-desktop is-8-tablet is-offset-2-tablet is-offset-0-desktop is-12-mobile">
              <figure className="image is-4by5">
                <img
                  src={
                    !!heroWithImage.image.src.childImageSharp
                      ? heroWithImage.image.src.childImageSharp.fluid.src
                      : heroWithImage.image.src
                  }
                  alt={heroWithImage.image.alt}
                  style={{
                    borderRadius: '40px',
                  }}
                />
              </figure>
            </div>
            <div className="column is-8 is-12-touch has-text-centered-mobile has-text-left-tablet mt-50-touch mt-0-mobile mb-38-mobile">
              <h3>{heroWithImage.title}</h3>
              <ShortHorizontalLine className="my-4" isCenteredMobileOnly />
              {heroWithImage.description && (
                <p className="body-large has-text-left">
                  {heroWithImage.description}
                </p>
              )}
              {heroWithImage.bulletPoints && (
                <ul className="body-small has-text-left info-points mt-6">
                  {heroWithImage.bulletPoints.map((point) => (
                    <li className="my-3">{point}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      <StayInTouchForEmployersSection
        title="Schedule your Carefully demo"
        description="Fill in your info below to learn how you can start providing childcare options to all your employees."
        tag="Employer"
      />
      <Testimonials testimonials={testimonials} />
      <QuoteWithLogo
        image={quoteWithImage.image.src}
        author={quoteWithImage.author}
        imageAlt={quoteWithImage.image.alt}
        quote={quoteWithImage.quote}
      />
    </div>
  )
}

ForEmployersPageTemplate.propTypes = {
  firstSection: PropTypes.shape({
    bigImage: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
      isRounded: PropTypes.bool,
    }),
    logo: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
    downloadDescription: PropTypes.string,
  }),
  secondSection: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    benefits: PropTypes.array,
  }),
  thirdSection: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.array,
  }),
  heroWithImage: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    bulletPoints: PropTypes.array,
  }),
  quoteWithImage: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
    author: PropTypes.string,
    quote: PropTypes.string,
  }),
  testimonials: PropTypes.array,
}

const ForEmployersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ForEmployersPageTemplate
        mainSection={frontmatter.firstSection}
        secondSection={frontmatter.secondSection}
        thirdSection={frontmatter.thirdSection}
        heroWithImage={frontmatter.heroWithImage}
        quoteWithImage={frontmatter.quoteWithImage}
        logo={frontmatter.logo}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

ForEmployersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ForEmployersPage

export const pageQuery = graphql`
  query ForEmployersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "for-employers-page" } }) {
      frontmatter {
        firstSection {
          title
          subtitle
          logo {
            src {
              childImageSharp {
                fluid(maxWidth: 170, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          bigImage {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
            isRounded
          }
          downloadDescription
        }
        secondSection {
          title
          subtitle
          description
          benefits
        }
        thirdSection {
          title
          description
          items {
            title
            description
            bigText {
              text
              prefix
            }
          }
        }
        heroWithImage {
          title
          description
          bulletPoints
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        quoteWithImage {
          author
          quote
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        testimonials {
          text
          author
        }
      }
    }
  }
`
